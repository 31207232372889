<template>
  <div
    class="columns is-mobile is-centered"
  >
    <b-loading
      :is-full-page="isFullPage"
      v-model="isLoading"
      :can-cancel="true"
    />
    <div class="column mt-6 is-full-mobile is-one-third-tablet px-5">
      <form @submit.prevent="validate">
        <div
          class="is-flex is-flex-direction-column is-align-items-center"
        >
          <img
            src="../assets/IDM.png"
            alt="logo"
            style="max-width: 155px; width: 155px"
          />
          <b-field
            label="Эл. почта"
            :type="!!errors.email ? 'is-danger' : 'text'"
            :message="errors.email"
          >
            <b-input
              v-model="email"
              type="email"
            >
            </b-input>
          </b-field>
          <b-field label="Пароль" class="mb-6">
            <b-input
              v-model="password"
              type="password"
              password-reveal
            >
            </b-input>
          </b-field>
          <b-notification
            v-if="!!errorMessage"
            :closable="false"
            type="is-danger"
            has-icon
            class="p-3 "
            style="width: 100%"
            role="alert"
          >
            <span style="line-height: 16px">{{ errorMessage }}</span>
          </b-notification>
          <button class="button is-info px-6 py-3">Войти</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      errors: {},
      email: undefined,
      password: undefined,
      errorMessage: undefined,
      isLoading: false,
      isFullPage: true,
      errorTimeout: null,
    }
  },
  methods: {
    showErrorMessage(message = "Неверный логин или пароль, пожалуйста введите корректные данные!") {
      this.errorMessage = message
      this.errors = {}
      if (this.errorTimeout) clearTimeout(this.errorTimeout)
      this.errorTimeout = setTimeout(() => {
        this.errorMessage = undefined
        this.errors = {}
      }, 5000)
    },

    validate() {
      const regexEmail = /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/
      if (regexEmail.test(this.email)) this.SignIn()
      else this.errors = { email: 'неверный формат e-mail' }
    },

    async SignIn() {
      this.isLoading = true
      try {
        const { data } = await this.$axios.post(`users/signin`, {
          email: this.email,
          password: this.password,
        })
        if (data.token) {
          localStorage.setItem("token", data.token)
          this.$axios.defaults.headers.common["token"] = data.token
          this.$router.push(data.user?.["role_id"] === 2 ? "/" : "/global")
        } else {
          this.showErrorMessage()
        }
      } catch (e) {
        this.showErrorMessage()
      }
      this.isLoading = false
    },
  },
}
</script>

